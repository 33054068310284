<template>
  <div class="grid h-screen w-screen bg-primary-50 sm:grid-cols-[auto_1fr]">
    <div
      class="relative z-10 hidden w-20 grid-rows-[auto_1fr_auto] bg-white p-4 shadow-lg transition-[width] sm:grid"
    >
      <app-image
        alt="Pollen logo"
        class="mx-auto"
        :height="40"
        src="/images/logo_color.svg"
        :width="40"
      />

      <app-navigation-menu
        class="mt-6"
        color="light"
        :expanded="false"
        :items="items"
      />

      <main-navigation-user-entry />
    </div>

    <main class="overflow-hidden">
      <slot />
    </main>
  </div>
</template>

<script lang="ts" setup>
import type { NavigationMenuItem } from "@pollen/ui-library/client/shared/navigation/navigation.model";

import MainNavigationUserEntry from "~/layouts/_includes/components/main-navigation-user-entry.vue";

const {
  public: {
    apps: { websiteBaseUrl },
  },
} = useRuntimeConfig();

const items: NavigationMenuItem[] = [
  {
    label: "Sessions",
    icon: "ph:certificate",
    to: {
      name: "index-sessions",
    },
  },
  {
    label: "Catalogue",
    icon: "ph:books",
    href: `${websiteBaseUrl}/search`,
  },
];
</script>
